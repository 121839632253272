export const copyToClipboard = (itemContent, copyButton) => {
    navigator.clipboard.writeText(itemContent).then(() => {
        console.log('Copied to clipboard');

        const copyButtonOldText = copyButton.innerText;
        // Change the button text to indicate success
        copyButton.innerHTML = "Copied &#10003";

        // Reset the button text after a short delay, for example 1.5 seconds
        setTimeout(() => {
            copyButton.innerText = copyButtonOldText;
        }, 1500);
        
    }).catch((error) => {
        console.error('Failed to copy to clipboard', error);
    });
}