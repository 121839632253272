import { initAfterDOMLoaded } from '../utilities/initAfterDOMLoaded.js';
import { copyToClipboard } from '../utilities/copy-to-clipboard.js';
import { swapMustacheTokens } from '../utilities/swap-mustache-tokens.js';

const seatpostCalculator = document.querySelector('.seatpost-tool');

const initSeatpostCalculator = () => {
    if (seatpostCalculator) {
        const form = seatpostCalculator.querySelector('form');
        form.addEventListener('submit', async(event) => {
            event.preventDefault();
            const formData = new FormData(form)
            const data = await callSeatposts(formData);
            const longestAndShortest = getLongestAndShortest(data)
            renderResults(longestAndShortest);
            const resetButton = seatpostCalculator.querySelector('#calculate-another-post')
            
            resetButton.addEventListener('click', (e) => calculateAnotherPost(e))
            attachCopyToClipboard()
        }
        );
    }
}

async function callSeatposts(formData) {
    try {
        const diameter = formData.get('diameter');
        const collarToSeatRail = formData.get('collarToSeatRail');
        const maxInsertion = formData.get('maxInsertion');

        const queryParams = new URLSearchParams({
            diameter,
            collarToSeatRail,
            maxInsertion
        });

        // Send to server
        const response = await fetch(`/api/seatpostfit/calc?${queryParams.toString()}`);

        // If the server returns JSON, parse it
        const result = await response.json();
        console.log("Server result:", result);
        return result
    } catch (err) {
        console.error("Error:", err);
    }
}

function renderResults(posts) {
    const outputContainer = seatpostCalculator.querySelector('#output');
    outputContainer.innerHTML = '';

    // Get references to the templates
    const containerTemplate = seatpostCalculator.querySelector('#seatpost-results-template');
    const itemTemplate = seatpostCalculator.querySelector('#seatpost-results-item');

    // Clone the container template
    const containerClone = containerTemplate.content.cloneNode(true);

    // Locate the area in the container clone where individual items will go
    const itemsArea = containerClone.querySelector('.seatpost-items-area');

    // Iterate over sorted data to create individual items
    posts.forEach((post, index) => {
        
        const itemClone = itemTemplate.content.cloneNode(true);
        const shortestText = itemClone.querySelector('.seatpost-type-title').dataset.shortest 
        const longestText = itemClone.querySelector('.seatpost-type-title').dataset.longest
        if(index === 0) {
        itemClone.querySelector('.seatpost-type-title').textContent = shortestText;
        let copyButton = itemClone.querySelector('.copy-to-clipboard');
        copyButton.textContent = swapMustacheTokens(copyButton.textContent, post) + shortestText;
        } else {
            itemClone.querySelector('.seatpost-type-title').textContent = longestText;
            let copyButton = itemClone.querySelector('.copy-to-clipboard');
            copyButton.textContent = swapMustacheTokens(copyButton.textContent, post) + longestText;
        }
        
        let travelDiameter = itemClone.querySelector('.seatpost-length-diameter')
        travelDiameter.textContent = swapMustacheTokens(travelDiameter.textContent, post);
            
        let seatpostDiameterText = itemClone.querySelector('.seatpost-length-diameter-text');
        seatpostDiameterText.textContent = swapMustacheTokens(seatpostDiameterText.textContent, post);

        let codeAndDescription = itemClone.querySelector('.seatpost-part-recommendation');
        codeAndDescription.textContent = `${post.FgCode} - ${post.Description}`;

        // Append the populated item to the items area
        itemsArea.appendChild(itemClone);
    });

    // Append the entire container clone to the output
    outputContainer.appendChild(containerClone);
}


function calculateAnotherPost(e) {
    e.preventDefault()
    const output = seatpostCalculator.querySelector('#output')
    output.innerHTML = ''
}

function getLongestAndShortest(data) {
    const sortedData = [...data].sort((a, b) => a.OverallLength - b.OverallLength);
    const shortest = sortedData[0];
    const longest = sortedData[sortedData.length - 1];
    return [shortest, longest]
}

// clipboard
function attachCopyToClipboard() {
    const copyButtons = seatpostCalculator.querySelectorAll('.copy-to-clipboard');
    copyButtons.forEach(button => {
        button.addEventListener('click', (e) => {
            const itemContent = e.target.closest('.seatpost-part').querySelector('.seatpost-part-recommendation').textContent;
            copyToClipboard(itemContent, e.target);
        });
    });
}

initAfterDOMLoaded(initSeatpostCalculator)